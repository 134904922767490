import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-jps-steelsolutions-pt/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import ContactsPage from "../components/contacts.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Contactos" description="JPS Steel Solutions é uma Serralharia especializada em construções de aço inox." image={'https://www.jps-steelsolutions.pt' + ogImage} siteUrl={'https://www.jps-steelsolutions.pt' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <strong>JPS Steel Solutions</strong><br />
Rua Luciana Correia Marques<br />
4250-822 Santa Maria da Feira<br />
Portugal<br />
Loc: 40.641327, -8.653632<br />
Telefone: 256083590<br />
Email: geral@jps-steelsolutions.pt<br />
Telémovel: +351939899154
    </MdxLayout>
    <div style={{
      maxWidth: 900,
      margin: '0 auto'
    }}>
    <ContactsPage mdxType="ContactsPage" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      